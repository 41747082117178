import {html} from 'lit-html';
import styles from './Hero.css';
import {Parallax} from './Shared';

export default ({image, title, subtitle}) => html`
    <div class="${styles.hero}">
        ${Parallax({image})}
        <div class="${styles.content}">
            <h1 class="${styles.title}">${title}</h1>
            <h2 class="${styles.subtitle}">${subtitle}</h2>
        </div>
    </div>
`;
