import {html} from 'lit-html';
import {unsafeHTML} from 'lit-html/directives/unsafe-html';
import styles from './Contacts.css';
import {SectionTitle, Jumper, Icon, Parallax} from './Shared';


const InfoItem = ({icon, label, value}) => html`
    <div class="${styles.infoItem}">
        <div class="${styles.infoIcon}">
            ${Icon(icon)}
        </div>
        <div class="${styles.infoLabel}">
            ${label}
        </div>
        <div class="${styles.infoValue}">
            ${value}
        </div>
    </div>
`;

const InfoSection = contacts => html`
    <div class="${styles.infoSection}">
        ${contacts.map(InfoItem)}
    </div>
`;

const MapSection = mapEmbed => html`
    <div class="${styles.mapSection}">
        <div class="${styles.map}">
            ${unsafeHTML(mapEmbed)}
        </div>
    </div>
`;

const formAction = ({progress, success, error}) => e => {
    e.preventDefault();

    const formMessage = document.querySelector('#formMessage');

    formMessage.innerText = progress;

    const resetMessage = callback => {
        setTimeout(() => {
            formMessage.innerText = '';
            callback();
        }, 1000);
    };

    const resetFormCallback = () => {
        e.target.reset();
    };

    fetch('/', {
        method: 'POST',
        body: new FormData(e.target)

    }).then(() => {
        formMessage.innerText = success;

        resetMessage(resetFormCallback);

    }).catch(() => {
        formMessage.innerText = error;

        resetMessage();
    });
};

const FormSection = ({nameField, emailField, messageField, sendLabel, statuses}) => html`
    <div class="${styles.formSection}">
        <form name="contact" method="post" @submit="${formAction(statuses)}">
            <input type="hidden" name="form-name" value="contact" />
            <div class="${styles.fields}">
                <div class="${styles.field}">
                    <label class="${styles.label}">${nameField}</label>
                    <input class="${styles.input}" type="text" name="name" required=""/>
                </div>
                <div class="${styles.field}">
                    <label class="${styles.label}">${emailField}</label>
                    <input class="${styles.input}" type="email" name="email" required=""/>
                </div>
                <div class="${styles.fieldWide}">
                    <label class="${styles.label}">${messageField}</label>
                    <textarea class="${styles.input}" name="message" cols="30" rows="6" required=""></textarea>
                </div>
            </div>
            <div class="${styles.buttonWrapper}">
                <div id="formMessage" class="${styles.formMessage}"></div>
                <button class="${styles.button}" type="submit">${sendLabel}</button>
            </div>
        </form>
    </div>
`;

export default ({title, image, contacts, mapEmbed, form}) => html`
    ${Jumper(title)}
    <div class="${styles.contacts}">
        <div class="${styles.wrapper}">
            ${Parallax({image, start: 'top-bottom', end: 'bottom-bottom'})}
            ${SectionTitle(title, undefined, 'inner-contacts')}
            <div class="${styles.contents}">
                ${InfoSection(contacts)}
                ${MapSection(mapEmbed)}
                ${FormSection(form)}
            </div>
        </div>
    </div>
`;
