import {html} from 'lit-html';
import styles from './Services.css';
import {SectionTitle, markdown} from './Shared';

const Service = ({title, description}) => html`
    <div class="${styles.serviceItem}">
        <div class="${styles.serviceTitle}">
            <h3>${title}</h3>
        </div>
        <div class="${styles.serviceDescription}">
            ${markdown(description)}
        </div>
    </div>
`;

export default ({title, items = []}) => html`
    <div class="${styles.services}">
        <div class="${styles.wrapper}">
            ${SectionTitle(title)}
            <div class="${styles.items}">
                ${items.map(Service)}
            </div>
        </div>
    </div>
`;
