import {html} from 'lit-html';
import styles from './Gallery.css';
import {SectionTitle} from './Shared';

const Tile = (image, index) => html`
    <div class="${styles.tile}">
        <img class="${styles.tileImage}" src="${image}" alt="IMG ${index}"/>
    </div>
`;

export default ({title, items}) => html`
    <div class="${styles.gallery}">
        <div class="${styles.wrapper}">
            ${SectionTitle(title)}
            <div class="${styles.tiles}">
                ${items.map(Tile)}
            </div>
        </div>
    </div>
`;
