import {html} from 'lit-html';
import * as basicScroll from 'basicscroll';
import slugify from 'slugify';
import Header from './Header';
import Hero from './Hero';
import Intro from './Intro';
import Departments from './Departments';
import Services from './Services';
import Gallery from './Gallery';
import Sponsors from './Sponsors';
import Contacts from './Contacts';
import Footer from './Footer';

const getMenuItem = (label, icon) => ({
    label,
    icon,
    href: `#${slugify(label, {lower: true})}`
});

export const App = data => {
    const menu = [
        getMenuItem('Home', 'ic:home'),
        getMenuItem(data.intro.title, 'ic:info'),
        getMenuItem(data.services.title, 'ic:settings'),
        getMenuItem(data.gallery.title, 'ic:camera'),
        getMenuItem(data.contacts.title, 'ic:mail')
    ];
    return html`
        <div id="home" class="app">
            ${Header(menu)}
            ${Hero(data.hero)}
            ${Intro(data.intro)}
            ${Departments(data.departments)}
            ${Services(data.services)}
            ${Gallery(data.gallery)}
            ${Sponsors(data.sponsors)}
            ${Contacts(data.contacts)}
            ${Footer(data.global.footer)}
        </div>
    `;
};

export const scrollEffects = () => {
    basicScroll.create({
        from: 0,
        to: 1200,
        props: {
            '--Parallax-translateY': {
                from: '-20vh',
                to: '20vh'
            }
        }
    }).start();

    const headerEffects = basicScroll.create({
        elem: document.querySelector('#root'),
    	from: 0,
    	to: 150,
        direct: false,
    	props: {
    		'--Header-opacity': {
    			from: .01,
    			to: .99
    		},
            '--Header-nav-opacity': {
                from: .4,
                to: .99
            },
    		'--Header-logo-scale': {
    			from: 2.2,
    			to: 1
    		},
    		'--Header-nav-padding': {
    			from: '20px',
    			to: '0px'
    		}
    	}
    });
    headerEffects.start();
};
