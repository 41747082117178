import {html} from 'lit-html';
import styles from './Header.css';
import {Icon} from './Shared';

const Item = ({label, icon, href}) => html`
    <a class="${styles.item}" href="${href}">
        <div class="${styles.itemIcon}">
            ${Icon(icon)}
        </div>
        <div class="${styles.itemLabel}">${label}</div>
    </a>
`;

export default menu => html`
    <div class="${styles.header}">
        <div class="${styles.wrapper}">
            <div class="${styles.logoWrapper}">
                <div class="${styles.logo}"></div>
            </div>
            <nav class="${styles.nav}">
                ${menu.map(Item)}
            </nav>
        </div>
    </div>
`;
