import {html} from 'lit-html';
import {unsafeHTML} from 'lit-html/directives/unsafe-html';
import marked from 'marked';
import styles from './Shared.css'; // eslint-disable-line css-modules/no-unused-class
import slugify from 'slugify';

export const Jumper = id => html`
    <span id="${slugify(id, {lower: true})}" class="${styles.jumper}" data-jumper=""></span>
`;

export const SectionTitle = (title, classes = styles.sectionTitle, id) => html`
    <div class="${classes}">
        <h2>
            ${Jumper(id || title)}
            ${title}
        </h2>
    </div>
`;

export const Icon = icon => html`
    <span class="iconify" data-icon="${icon}" data-inline="false"></span>
`;

export const getParallaxStyles = image => `background-image: url("${image}");`;

export const Parallax = ({image, start = 'top-top', end = 'bottom-top', from = '-20vh', to = '20vh'}) => {
    const imageStyle = getParallaxStyles(image);
    return html`
        <div class="${styles.parallax}"
             data-parallax=""
             data-start="${start}"
             data-end="${end}"
             data-from="${from}"
             data-to="${to}">
            <div class="${styles.parallaxImage}" style="${imageStyle}"></div>
        </div>
    `;
};

export const markdown = content => unsafeHTML(marked(content));
