import {html} from 'lit-html';
import styles from './Departments.css';
import {Jumper, Parallax, markdown} from './Shared';

const Department = ({title, description}) => html`
    <div class="${styles.department}">
        <h3 class="${styles.title}">
            ${title}
        </h3>
        <p>
            ${markdown(description)}
        </p>
    </div>
`;

export default ({image, items = []}) => items.length ? html`
    <div class="${styles.departments}">
        ${Jumper('departments')}
        ${Parallax({image, start: 'top-bottom'})}
        <div class="${styles.contents}">
            ${items.map(Department)}
        </div>
    </div>
` : '';
