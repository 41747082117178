import {html} from 'lit-html';
import styles from './Sponsors.css';
import {SectionTitle} from './Shared';

const Sponsor = ({label, image}) => html`
    <img src="${image}" alt="${label}" class="${styles.item}"/>
`;

export default ({title, items}) => items.length >= 1 ? html`
    <div class="${styles.sponsors}">
        ${SectionTitle(title)}
        <div class="${styles.items}">
            ${items.map(Sponsor)}
        </div>
    </div>
` : '';
