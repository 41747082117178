import {render} from 'lit-html';
import {App, scrollEffects} from './partials';
import '@iconify/iconify';
import './style.css';
import data from './data/*.yml';

const setTheme = (el, {themeEnabled, primary, secondary, terziary}) => {
    if (themeEnabled) {
        el.style.setProperty('--primary-background-color', primary.primaryBackgroundColor);
        el.style.setProperty('--primary-text-color', primary.primaryTextColor);
        el.style.setProperty('--secondary-background-color', secondary.secondaryBackgroundColor);
        el.style.setProperty('--secondary-text-color', secondary.secondaryTextColor);
        el.style.setProperty('--terziary-background-color', terziary.terziaryBackgroundColor);
        el.style.setProperty('--terziary-text-color', terziary.terziaryTextColor);
    }
};

const renderApp = () => {
    const root = document.querySelector('#root');
    setTheme(root, data.theme);
    // root.style.setProperty('--mouse-x', e.clientX + "px");
    render(App(data), root);
    scrollEffects();
};

renderApp();
