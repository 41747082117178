import {html} from 'lit-html';
import styles from './Intro.css';
import {SectionTitle, markdown} from './Shared';

export default ({title, content}) => html`
    <div class="${styles.intro}">
        <div class="${styles.wrapper}">
            ${SectionTitle(title, styles.title)}
            <div class="${styles.content}">
                ${markdown(content)}
            </div>
        </div>
    </div>
`;
